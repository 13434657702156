<template>
  <div class="register-bg">
    <!-- <div style="padding:0 20px;">
      <img
        src="@/assets/images/grab-red-packet/text.png"
        alt=""
      >
    </div> -->
    <div class="register-box">
      <ul>
        <li>
          <div class="garage-img">
            <img
              :src="(garageIntroduction.url) ? avatarUrl(garageIntroduction.url):require('@/assets/images/grab-red-packet/default.png')"
              alt=""
            >
          </div>
          <div class="garage-content">
            <h1 class="garage-name">{{garageIntroduction.name}}</h1>
            <p class="prize-text">送您价值<span>998元/年</span>智能门店管理系统</p>
          </div>
        </li>
      </ul>
    </div>
    <!--------文字内容------>
    <div class="content">
      <h1>填写信息</h1>
      <ul>
        <li>
          <cube-input
            class="input"
            placeholder="请输入门店名称"
            v-model="companyName"
            @blur="blurName"
          ></cube-input>
        </li>
        <li>
          <cube-input
            class="input"
            placeholder="请输入手机号"
            v-model="mobilePhone"
            @blur="blurName"
          ></cube-input>
        </li>
        <li>
          <cube-input
            class="input"
            placeholder="请输入验证码"
            v-model="vcode"
            @blur="blurName"
          ></cube-input>
          <span
            v-if="sendCaptchaEnabled"
            class="get-code"
            @click="sendCaptcha()"
          >{{getVcode}}</span>
          <span
            v-else
            class="after-get-code"
          >{{counterTimer}}秒后重新获取</span>
        </li>
        <li>
          <div
            class="receive"
            @click="loggingOn"
          >
            立即领取
          </div>
        </li>
      </ul>
    </div>
    <!-- 三大优势 -->
    <div class="advantages">
      <img
        src="~images/grab-red-packet/advantages-add.png"
        alt=""
      >
    </div>
  </div>
</template>

<script>
import { preservation, getSendVCode, storeInformation } from 'api/redpacket'
import { isWeiXin, isIOS } from 'utils/wx'
export default {
  data () {
    return {
      mobilePhone: '',
      companyName: '',
      vcode: '',
      sendCaptchaEnabled: true,
      sending: false,
      getVcode: '获取验证码',
      counterTimer: 60,
      recommendedUserId: '',
      id: '',
      bodyHeight: {
        height: 'auto'
      },
      garageIntroduction: {
        name: '',
        url: ''
      },
      phoneHeight: 0
    }
  },
  mounted () {
    this.recommendedUserId = this.$route.query.userId
    this.phoneHeight = document.body.clientHeight || document.documentElement.clientHeight
    this.repairDepot(this.recommendedUserId)
    // 是否归为
    let isReset = true
    console.log('isIOS', isIOS())
    console.log('isWeiXin', isWeiXin())
    if (isIOS() && isWeiXin()) {
      document.body.addEventListener('focusin', () => {
        // 软键盘弹出的事件处理
        isReset = false
        console.log('软键盘弹出')
      })
      document.body.addEventListener('focusout', () => {
        // 软键盘收起的事件处理
        isReset = true
        console.log('软键盘收起')
        setTimeout(() => {
          // 当焦点在弹出层的输入框之间切换时先不归位
          if (isReset) {
            // 失焦后强制让页面归位
            window.scroll(0, 0)
          }
        }, 300)
      })
    }
  },
  methods: {
    blurName () {
      // this.$set(this.bodyHeight, 'height', (document.body.clientHeight || document.documentElement.clientHeight) + 'px')
      setTimeout(() => {
        const newHeight = document.body.clientHeight || document.documentElement.clientHeight
        if (this.phoneHeight - newHeight > 200) return (document.body.scrollTop = document.body.scrollHeight)
      }, 300)
    },
    avatarUrl (avatar) {
      let path = avatar || ''
      return process.env.VUE_APP_IMAGE_SERVER_URL + path
    },
    repairDepot (id) {
      storeInformation(id).then((res) => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }
        this.garageIntroduction.name = res.data.data.name
        this.garageIntroduction.url = res.data.data.url
      })
    },
    sendCaptcha () {
      if (!this.companyName.trim()) {
        const toast = this.$createToast({
          txt: `门店名称不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.mobilePhone.trim()) {
        const toast = this.$createToast({
          txt: `手机号码不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!/^1\d{10}$/.test(this.mobilePhone.trim())) {
        const toast = this.$createToast({
          txt: `手机号错误，请重新输入！`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.sendCaptchaEnabled) {
        return
      }
      if (this.sending) {
        return
      }
      this.sending = true
      let params = {
        phone: this.mobilePhone
      }
      getSendVCode(JSON.stringify(params)).then((res) => {
        this.sending = false
        let { result, desc } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        this.sendCaptchaEnabled = false
        this.timer = setInterval(() => {
          if (this.counterTimer <= 0) {
            this.counterTimer = 60
            this.sendCaptchaEnabled = true
            this.getVcode = '重新获取'
            clearInterval(this.timer)
          }
          this.counterTimer--
        }, 1000)
      })
    },
    loggingOn () {
      if (!this.companyName.trim()) {
        const toast = this.$createToast({
          txt: `门店名称不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.mobilePhone.trim()) {
        const toast = this.$createToast({
          txt: `手机号码不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!/^1\d{10}$/.test(this.mobilePhone.trim())) {
        const toast = this.$createToast({
          txt: `手机号错误，请重新输入！`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.vcode.trim()) {
        const toast = this.$createToast({
          txt: `验证码不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (this.vcode.trim().length < 6) {
        const toast = this.$createToast({
          txt: `验证码不正确`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (this.sending) {
        return
      }
      let params = {
        phone: this.mobilePhone,
        vcode: this.vcode,
        recommendedUserId: this.recommendedUserId,
        companyName: this.companyName
      }
      this.sending = true
      preservation(JSON.stringify(params)).then((res) => {
        this.sending = false
        let { result, desc } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        this.$router.replace({ path: '/dc/download' })
      })
    }
  }
}
</script>

 <style lang="scss" scoped>
@import "~styles/index.scss";
*html {
  background: linear-gradient(
    90deg,
    rgba(131, 29, 194, 1) 0%,
    rgba(176, 36, 161, 1) 52%,
    rgba(245, 47, 91, 1) 100%
  );
}
img {
  width: 100%;
  height: auto;
  width: auto\9; /* ie8 */
  display: block;
  -ms-interpolation-mode: bicubic;
}

.register-bg {
  background: url("~images/grab-red-packet/register-bg-new.png") no-repeat top
    center;
  padding: px2rem(250px) 0 px2rem(64px) 0;
  background-size: cover;
  // position: fixed;
  // position: absolute;
  // z-index: -1;
  width: 100%;
  // height: 100vh;
  // top: 0px;
  // bottom: 0px;
  // box-sizing: border-box;
  overflow-y: scroll;
}
.register-box {
  background: url("~images/grab-red-packet/register-title-new.png") no-repeat
    top center;
  background-size: cover;
  width: px2rem(700px);
  height: px2rem(198px);
  margin: 0 auto px2rem(16px) auto;
  ul {
    padding: px2rem(44px) px2rem(50px);
    // width: 100%;
    overflow: hidden;
    li {
      display: flex;
      justify-content: flex-start;
    }
    .garage-img {
      width: px2rem(112px);
      height: px2rem(112px);
      display: inline-block;
      border-radius: 100%;
      img {
        overflow: hidden;
        width: px2rem(112px);
        height: px2rem(112px);
        border-radius: 100%;
        background: #fff;
      }
    }
    .garage-content {
      display: table-cell;
      padding-left: px2rem(14px);
      padding-top: px2rem(15px);
      h1 {
        font-size: px2rem(36px);
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: px2rem(50px);
      }
      p {
        display: table-cell;
        font-size: px2rem(28px);
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: px2rem(40px);
      }
    }
  }
  .garage-name {
    height: px2rem(45px);
    font-size: px2rem(32px);
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: px2rem(45px);
  }
  .prize-text {
    height: px2rem(42px);
    font-size: px2rem(30px);
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: px2rem(42px);
    span {
      color: #fec146;
      font-weight: bold;
    }
  }
}
.content {
  background: url("~images/grab-red-packet/register-content-new.png") no-repeat
    top center;
  background-size: cover;
  width: px2rem(700px);
  height: px2rem(686px);
  margin: 0 auto px2rem(22px);
  h1 {
    font-size: px2rem(48px);
    font-weight: 600;
    color: #fe643a;
    line-height: px2rem(67px);
    text-align: center;
    padding-top: px2rem(80px);
  }
  ul {
    width: px2rem(700px);
    margin: px2rem(30px) auto;
    li {
      width: px2rem(584px);
      margin: 0 auto px2rem(30px);
      position: relative;
      .input {
        border-radius: px2rem(6px);
        border: px2rem(2px) solid rgba(254, 135, 87, 1);
        padding-left: px2rem(20px);
        height: px2rem(84px);
      }
      .receive {
        font-size: px2rem(32px);
        width: px2rem(540px);
        height: px2rem(90px);
        background: linear-gradient(
          90deg,
          rgba(255, 149, 90, 1) 0%,
          rgba(255, 79, 81, 1) 100%
        );
        border-radius: 6px;
        text-align: center;
        line-height: px2rem(90px);
        color: #fff;
      }
      .get-code {
        font-size: px2rem(28px);
        font-weight: 400;
        color: rgba(254, 135, 87, 1);
        line-height: px2rem(40px);
        position: absolute;
        right: px2rem(30px);
        top: px2rem(23px);
        padding-left: px2rem(30px);
        border-left: 1px solid #e4e4e4;
        display: inline-block;
      }
      .after-get-code {
        font-size: px2rem(28px);
        font-weight: 400;
        color: rgba(254, 135, 87, 1);
        line-height: px2rem(40px);
        position: absolute;
        right: px2rem(30px);
        top: px2rem(23px);
        padding-left: px2rem(30px);
        border-left: 1px solid #e4e4e4;
      }
    }
  }
}
.advantages {
  width: px2rem(700px);
  height: px2rem(300px);
  margin: 0 auto;
}
</style>
