/*
 * @Author: xuhongwei
 * @Date: 2019-07-01 14:55:39
 * @LastEditTime: 2020-03-04 11:31:23
 * @LastEditors: xuhongwei
 * @Description: 
 */
import ajax from './axios'
// 保存
export const preservation = params => {
  return ajax.post('/garage/referral/create', params)
}

// 注册获取验证码
export const getSendVCode = params => {
  return ajax.post('/garage/referral/sendVCode', params)
}

// 根据Id查询门店信息
export const storeInformation = id => {
  return ajax.get(`/garage/garage/manage/${id}/garageDetailH5`)
}
